<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        If the air temperature in your experiment was measured at
        <number-value :value="temp" unit="^\circ\text{C}" />
        and the barometric pressure was given as
        <number-value :value="pressure" unit="\text{mmHg,}" />
        what volume of
        <chemical-latex content="H2," />
        in
        <stemble-latex content="$\text{mL,}$" />
        would you expect to produce if you reacted
        <number-value :value="massCa" unit="\text{g}" />
        of
        <chemical-latex content="Ca" />
        with an excess of
        <chemical-latex content="HCl?" />
        Assume the vapor pressure of water is negligible.
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Volume}:$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemOleMiss116PRL2Q2part1',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
    massCa() {
      return this.taskState.getValueBySymbol('massCa').content;
    },
  },
};
</script>
